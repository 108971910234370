import React from 'react'

import Layout from '@components/layout/rootLayout.js'
import IndexPage_SEO from '@components/main/indexpage_seo.js'

const Default = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <IndexPage_SEO />
  </Layout>
)

export default Default;
