import React, { useState, useEffect, useRef, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import IndexPage_Header from './previewer/indexpage_header.js'
import { localizedUrl } from '@src/components/link'
import Linker from '@src/common/linker'
import Nav from '@components/nav/nav'

import { On, Row, CenterColumn } from '@src/style/globalStyle'
import { Desktop, Mobile, Header, IndexpageRoot } from './styles'
import { device, core } from '@src/style'

import API from '../../common/api.js'

import theme from '@src/style/theme'
import { injectIntl } from 'react-intl'
import typography from '@src/style/typography'
import styled, { css } from 'styled-components'
import { LongYellowButton } from '@components/buttons/style'
import { Form } from 'reactstrap'

const FullscreenWrapper = styled.div`
  width: 100%;
  background-color: ${props => props.theme.main.bg};
  color: ${props => props.theme.main.rows.white.textColor};
`

const H1 = styled(core.H1)`
  ${typography.headline.S}
  font-weight: normal;
  @media ${device.tablet} {
    padding-top: 2rem;
  }
  @media ${device.laptop} {
    padding-top: 0;
    ${typography.headline.S}
    font-weight: normal;
  }
  @media ${device.laptopL} {
    ${typography.headline.M}
  }
`
const H2 = styled(core.H2)`
  padding-top: 1rem;
  ${typography.headline.XXS}
  font-weight: normal;

  @media ${device.tablet} {
    ${typography.headline.XS}
  }
  @media ${device.laptop} {
    ${typography.headline.XS}
    font-weight: normal;
  }
  @media ${device.laptopL} {
    ${typography.headline.S}
    font-weight: normal;
  }
`

const ColumnFlex = styled(CenterColumn)`
  flex: 1;
  &:nth-child(2) {
    flex: 2;
  }
`

const ARCMask = styled.div`
  padding: 0 2rem;
  width: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
  }
  max-width: 450px;
`
const ARCEnhance = styled.div`
  padding: 0 2rem;
  width: 100%;
  max-width: 450px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`
const Text = styled(core.PM)`
  ${typography.headline.XS}

  @media ${device.mobile} {
    ${typography.paragraph.XS}
  }
  @media ${device.laptop} {
    ${typography.paragraph.S}
  }
  @media ${device.laptopL} {
    ${typography.paragraph.L}
  }
`

const List = styled.ul``
const Item = styled.li``

const ContentColumn = ({ locale }) => (
  <Fragment>
    <H1>
      <FormattedMessage id='index.seo.title' />
    </H1>
    <List>
      <Item>
        <H2>
          <FormattedMessage id='index.seo.item1' />
        </H2>
      </Item>
      <Item>
        <H2>
          <FormattedMessage id='index.seo.item2' />
        </H2>
      </Item>
      <Item>
        <H2>
          <FormattedMessage id='index.seo.item3' />
        </H2>
      </Item>
      <Item>
        <H2>
          <FormattedMessage id='index.seo.item4' />
        </H2>
      </Item>
      <Item>
        <H2>
          <FormattedMessage id='index.seo.item5' />
        </H2>
      </Item>
    </List>

    <Text>
      <FormattedMessage id='index.seo.text1' />
    </Text>
    <Text>
      <FormattedMessage id='index.seo.text2' />
    </Text>

    <LongYellowButton
      onClick={() => {
        Linker.openUrl(localizedUrl('/', locale))
      }}
    >
      <FormattedMessage id='index.seo.button' />
    </LongYellowButton>
  </Fragment>
)

export default injectIntl(({ intl: { locale } }) => {
  return (
    <IndexpageRoot>
      <Nav defaultIndex={0} seo />

      <FullscreenWrapper>
        <On tablet mobile>
          <Mobile>
            <Row>
              <ARCMask>
                <img src='/res/arcmask.jpg' alt='ARCMask' />
              </ARCMask>
              <ARCEnhance>
                <img src='/res/arcenhance.jpg' alt='ARCEnhance' />
              </ARCEnhance>
            </Row>
            <ContentColumn locale={locale} />
          </Mobile>
        </On>
        <On laptop desktop>
          <Desktop>
            <Row>
              <ColumnFlex>
                <ARCMask>
                  <img src='/res/arcmask.jpg' alt='ARCMask' />
                </ARCMask>
              </ColumnFlex>
              <ColumnFlex>
                <ContentColumn locale={locale} />
              </ColumnFlex>
              <ColumnFlex>
                <ARCEnhance>
                  <img src='/res/arcenhance.jpg' alt='ARCRetouch' />
                </ARCEnhance>
              </ColumnFlex>
            </Row>
          </Desktop>
        </On>
      </FullscreenWrapper>
    </IndexpageRoot>
  )
})
